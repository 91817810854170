import HomeHeader from "../components/Home/HomeHeader";
import MainApp from "../components/Home/MainApp";

const Home = () => {
  return (
    <div className="home">
      <HomeHeader />
      <div className="header-info">
        <div>
          <p>
            Please attend one of Wildwood Calvary Chapels Christmas Eve services
            that we will be offering, by reserving your seats below!
          </p>
        </div>
      </div>
      <MainApp />
    </div>
  );
};

export default Home;
