import headerGraphic from "../../assets/images/cr2024.png";

const HomeHeader = () => {
  return (
    <div className="home_header">
      {/* <img
        className="home_header-graphic"
        src={headerGraphic}
        alt="main-logo"
      /> */}
    </div>
  );
};

export default HomeHeader;
